import React from 'react';

function ForgotPasswordPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Forgot Password Page</h1>
      <p>TO BE IMPLEMENTED</p>
    </div>
  );
}

export default ForgotPasswordPage;
